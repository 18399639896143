:root {
  --red: #fb021c;
  --mediumRed: #e10f1c;
  --darkRed: #c62039;
  --purple: #64579a;
  --darkBlue: #022546;
  --mediumDarkBlue: #4767b7;
  --mediumBlue: #2273be;
  --mediumLightBlue: #3ca7f9;
  --blue: #058cf9;
  --lightBlue: #a3eafe;
  --lighterBlue: #c6f3ff;
  --faintBlue: #f3ffff;
  --transparentBlue: rgba(243, 255, 255, 0.3);
  --shadowGrey: #a3a0a0;

  // Patient Survey
  --faintBlue: #f3ffff;
  --pGray: #727e89;
  --palePurpleDark: #a0c6ff;
  --palePurpleMedium: #cae0f2;
  --palePurpleLight: #cae0f2;

  // Intelligent Scheduling
  --purpleDeep: #374175;
  --purpleGray: #839eb7;
  --purpleLightGray: #a8c6e2;
  --lavender: #9399e2;
  --bluePale: #e5f3fa;
  --blueSky: #84c8fd;
  --blueDocsperaDark: #1348d6;
  --blueDocsperaLight: #40b0fa;
  --medium-gray: #707070;
  --light-gray: #c9c9c9;
  --lighter-gray: #dddbdb;
  --box-shadow-card: 0px 3px 6px #00000029;
  --icon-idle-gray: #9b9b9b;

  // Component Library Colors
  --blueDark: #022546;
  --blackDark: #343a40;
  --blackDarkHover: #485159;
  --blueDocspera: #0096fa;
  --blueDocsperaHover: #33aeff;
  --blueIntelHover: #359efa;
  --blueFaint: #ebffff;
  --blueLight: #a3eafe;
  --blueMedium: #2273be;
  --blueMediumDark: #4767b7;
  --blueMediumLight: #359efa;
  --goldPending: #ffcc00;
  --goldPendingHover: #ffd940;
  --greenSuccess: #28a745;
  --greenSuccessHover: #2ebf4e;
  --greyDark: #646464;
  --greyHighlight: #ededed;
  --greySecondary: #6c757d;
  --greySecondaryHover: #818d96;
  --greyShadow: #a3a0a0;
  --red: #fb021c;
  --redDangerHover: #ff5264;
  --redDark: #c62039;
  --whiteIce: #f8f9fa;

  --hover-transition: all 150ms ease-in-out;
}

.intel {
  background-color: var(--darkBlue);
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

h1 {
  margin: 0;
  font-weight: 600;
}

h2 {
  color: var(--mediumBlue);
  font-family: 'Roboto';
  font-weight: 500;
  margin: 0;
}

h4 {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
}

p {
  margin: 0;
  color: var(--pGray);
  font-size: 20px;
}

.button {
  background-color: white;
  border-radius: 3px;
  color: var(--darkBlue);
  font-size: 16px;
  padding: 6px 16px 7px 16px;

  &:hover {
    background-color: rgb(168, 198, 255);
    color: var(--darkBlue);
  }

  &:focus {
    outline: none;
  }
}

.floating-box {
  box-shadow: -1px 1px 7px var(--shadowGrey);
  border-radius: 3px;
  background-color: white;
}

@keyframes throbber-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.throbber {
  border: 4px dotted var(--mediumLightBlue);
  border-radius: 100%;
  height: 16px;
  position: relative;
  margin-right: 10px;
  width: 16px;
  -webkit-animation: throbber-spin 1.5s cubic-bezier(0.67, 0.06, 0.18, 0.93) infinite;
  animation: throbber-spin 1.5s cubic-bezier(0.67, 0.06, 0.18, 0.93) infinite;

  &:before {
    background: white;
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    width: 5px;
    top: -4px;
    left: 6px;
  }
}

// DocSpera Font Faces
@font-face {
  font-family: 'Arimo';
  src: url('./fonts/Arimo/Arimo-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Arimo';
  src: url('./fonts/Arimo/Arimo-SemiBold.ttf') format('truetype');
  font-weight: semibold;
}
@font-face {
  font-family: 'Arimo';
  src: url('./fonts/Arimo/Arimo-Medium.ttf') format('truetype');
  font-weight: medium;
}
@font-face {
  font-family: 'Arimo';
  src: url('./fonts/Arimo/Arimo-Regular.ttf') format('truetype');
  font-weight: regular;
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 900;
  src: url('./fonts/OpenSans/OpenSans-ExtraBoldItalic-webfont.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 900;
  src: url('./fonts/OpenSans/OpenSans-ExtraBold-webfont.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('./fonts/OpenSans/OpenSans-BoldItalic-webfont.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('./fonts/OpenSans/OpenSans-Bold-webfont.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: url('./fonts/OpenSans/OpenSans-SemiboldItalic-webfont.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: url('./fonts/OpenSans/OpenSans-Semibold-webfont.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('./fonts/OpenSans/OpenSans-Regular-webfont.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 100;
  src: url('./fonts/OpenSans/OpenSans-LightItalic-webfont.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 100;
  src: url('./fonts/OpenSans/OpenSans-Light-webfont.ttf');
}

// Velys Font Faces (Nunito & Rubik)
@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito/Nunito-Bold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-SemiBold.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-Medium.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 300;
}

/*/ FLEXBOX CLASSES */
.flex {
  display: flex;
}

.flex.align-start {
  align-items: flex-start;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.align-center {
  align-items: center;
}

.flex.align-stretch {
  align-items: stretch;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.space-around {
  justify-content: space-around;
}

.flex.space-evenly {
  justify-content: space-evenly;
}

.flex.justify-start {
  justify-content: flex-start;
}

.flex.justify-center {
  justify-content: center;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flex.direction-row {
  flex-direction: row;
}

.flex.direction-column {
  flex-direction: column;
}

.flex.nowrap {
  flex-wrap: nowrap;
}

.flex.wrap {
  flex-wrap: wrap;
}
/*/ FLEXBOX CLASSES */
