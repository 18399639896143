.modal-bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 21;

  .modal {
    width: 90%;
    min-width: 450px;
    max-height: 949px;
    min-height: 300px;
    background-color: white;
    border-radius: 10px;

    .header {
      display: flex;
      justify-content: space-between;
      padding-left: 24px;
    }

    h4 {
      margin: 0;
      font-family: "Nunito";
      font-size: 20px;
    }

    .close {
      border-radius: 100%;
      width: 20px;
      height: 20px;
      padding: 0;
      margin-right: 24px;
      background-color: transparent;
      border: 0;

      > div {
        margin-left: 1px;
      }
    }

    .content-div {
      padding: 20px;
      padding-top: 20px;

      .date-tabs-div {
        display: flex;
        padding: 10px;
        padding-top: 0px;
        padding-right: 100px;
        border: 2px solid var(--light-gray);
        border-radius: 10px;
        align-items: flex-start;
        margin-top: 10px;
        margin-right: 10px;
        overflow-x: scroll;
        .date-div {
          padding-top: 10px;
          min-width: 100px;
        }
      }
      .date-tabs-div {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: 5px; /* Firefox */
      }
      .date-tabs-div::-webkit-scrollbar {
        // display: none; /* Safari and Chrome */
        width: 7px;
        height: 7px;
      }
    

      .caledar-picker input {
        height: 55px;
      }
      .caledar-picker {
        position: relative;
      }
    }
  }
}
