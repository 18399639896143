#horizontal-panel {
  padding-top: 20px;
  position: absolute;
  z-index: 3;
  width: 100%;
  display: flex;

  span {
    font-size: 14px;
  }

  #filters {
    z-index: 3;
    margin: 5px 0;
    display: flex;
    border: 1 solid pink;
    margin-right: 20px;

    > div {
      margin-right: 20px;
    }
  }

  #send-button {
    display: flex;
    flex-direction: column;
    margin-right: 40px;

    a {
      padding: 7px 15px 6px 15px;
    }
  }

  @media only screen and (max-width: 965px) {
    #send-button {
      margin-left: 0px;
      margin-top: 0px;
    }
  }
}
