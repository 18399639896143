.row {
  display: flex;
  flex-direction: row;
  height: 35px;
  align-items: center;

  .drag-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .comorbidities-svg {
    width: 30px;
    box-shadow: -0.5px 0.5px 10px rgba(0, 0, 0, 0.1);
    margin: 0 0 0 7px;
    padding: 0 !important;
    border-radius: 3px;
  }

  .comorb-list {
    position: absolute;
    background-color: white;
    box-shadow: -0.5px 0.5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    z-index: 9999;
    top: 0px;
    left: 40px;
    width: auto;
    padding-right: 10px;

    ul {
      margin: 0;
      padding: 10px 0px 10px 20px;

      li {
        margin: 0 5px;
        padding: 0;
        font-size: 14px;
        @media only screen and (max-width: 1575px) {
          font-size: 12px;
        }
      }
    }
  }

  .explanation {
    position: absolute;
    background-color: white;
    border-radius: 3px;
    color: black;
    padding: 5px 10px;
    z-index: 10;
    top: 0;
    left: 30px;
    box-shadow: -0.5px 0.5px 10px rgba(0, 0, 0, 0.2);
    width: 75px;
    font-size: 14px;
    @media only screen and (max-width: 1575px) {
      font-size: 12px;
    }
  }

  font-size: 14px;
  @media only screen and (max-width: 1575px) {
    font-size: 12px;
  }

  .status-col {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    position: relative;

    .status-box {
      background-color: white;
      padding: 5px;
      border-radius: 3px;
      box-shadow: rgb(228, 228, 228) 1px 1px 10px;
      display: flex;
      align-items: center;
    }

    .status-arrow-inactive {
      width: 0;
      height: 0;
      border-top: 7px solid grey;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      margin-left: 3px;
      margin-right: 3px;
    }

    .status-arrow-active {
      width: 0;
      height: 0;
      border-top: 7px solid black;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      margin-left: 3px;
      margin-right: 3px;
    }

    .status-circle {
      height: 12px;
      width: 12px;
      margin-right: 5px;
      border-radius: 100px;
      cursor: pointer;
    }

    .intake-icon-div {
      padding-left: 3px;
      border-right: 1px solid rgb(228, 228, 228);
      margin-right: 3px;
      display: flex;
      align-items: center;

      img {
        width: 25px;
      }
    }
  }
}
