::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}

#modal-component {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 20;
  display: block !important;
}

#background {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.7;
}

.survey-modal-div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 11;
  margin-top: 50px;
  height: 90%;
  max-height: 900px;
  max-width: 800px;
}

.modal {
  opacity: 1;
  background-color: white;
  padding-bottom: 130px;
  height: 53%;
  position: relative;
  border-radius: 10px;
  width: 100%;

  #modal-header {
    position: fixed;
    width: 770px;
  }

  @media (max-width: 900px) {
    #modal-header {
      position: static;
      width: auto;
    }
    #survey-content {
      border-top: 0 !important;
    }
  }

  @media (max-width: 650px) {
    .question {
      max-width: 500px;
    }
  }

  #survey-content {
    justify-self: center;
    margin: 10px auto;
    border-radius: 3px;
    padding: 30px 20px;
    border: 10px solid white;
    border-top: 110px solid white;
    height: 100%;
    overflow-y: scroll;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .question {
      align-self: center;
      width: 500px;
      padding-bottom: 20px;
      h4 {
        font-weight: 400;
        font-size: 14px;
        color: black;
        letter-spacing: normal !important;
      }
      p {
        font-size: 13px;
        padding-left: 20px;
        margin-top: 1px;
        color: var(--blueDocspera);
      }
    }

    .no-results {
      text-align: center;
    }

    .close-button {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-right: -20px;
      float: right;
      cursor: pointer;
      max-width: 100px;
      align-self: flex-end;
      margin-right: 10px;
      width: 80px;
      height: 35px;
      padding: 5px 3px;
    }
  }

  .left-info {
    margin-left: 10px;
    h3 {
      font-weight: 500;
      margin: 0;
      margin-bottom: 5px;
      color: var(--blueDocspera);
    }
    p {
      font-size: 14px;
      color: var(--blueDark);
    }
  }

  .exit {
    width: 30px;
    float: right;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

#modal {
  opacity: 1 !important;
}
