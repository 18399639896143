#timebar {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 20px;
  height: 85px;
  box-shadow: var(--box-shadow-card);
  border-radius: 10px;

  .start-block {
    border-radius: 10px 0 0 10px;
  }

  .end-block {
    border-radius: 0 10px 10px 0;
  }

  .quarter-block {
    flex-grow: 1;
    border: 1px solid var(--light-gray);
  }

  .start-of-hour {
    border-left: 1px solid var(--medium-gray);
  }

  .time-on-top {
    position: absolute;
    margin-top: -23px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  .bookable {
    position: relative;
    cursor: pointer;
  }

  #tooltip {
    position: absolute;
    bottom: -50px;
    border-radius: 5px;
    background-color: white;
    box-shadow: var(--box-shadow-card);
    width: 115px;
    padding: 5px;
  }

  #tooltip p {
    font-size: 12px;
  }
}
