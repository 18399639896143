#dashboard {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
}

.loading-view {
  display: flex;
  justify-content: center;
  width: 100%;
}

#bulk-send-btn {
  margin-right: 30px;
  div > span {
    width: max-content;
  }
}

#clear {
  margin-right: 30px;

  margin-top: 10px;
  button {
    width: max-content;
  }
}
