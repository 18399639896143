#table {
  // margin-top: 40px;

  #table-loading {
    background-color: white;
    position: absolute;
    z-index: 100;
    display: block;
    margin: 70px auto;
    top: 0;
    left: 0;
    right: 0;
    width: 100px;
    padding: 0 15px;
    border-radius: 3px;
    border: 1px solid var(--blueDocspera);
    box-shadow: 0.5px 0.5px 10px var(--shadowGrey);

    #loadingsplash {
      padding: 7px 0 10px 0;
      div {
        height: 12px;
        width: 12px;
      }

      h1 {
        font-size: 14px;
      }
    }
  }

  .loading-more-patients {
    >div {
      transform: scale(0.7);
      padding: 5px 0px 5px 0px;
    }
    h1 {
      display: none;
    }
  }
}

#priority-hdr-div {
  width: 80px;
  display: flex;
  padding-left: 30px;
  img {
    width: 12px;
    height: 10px;
  }
  @media only screen and (max-width: 1000px) {
    padding-left: 20px;
    width: 90px;
  }
}

.priority-hdr {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
}

.priority-col {
  width: 90px;
  display: flex;
  img {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  margin-left: 15px;
}

.physician-col {
  box-sizing: border-box;
  display: flex;
  width: 120px;
  margin-left: 20px;
  @media only screen and (max-width: 1575px) {
    width: 100px;
  }
}

.patient-col {
  box-sizing: border-box;
  width: 135px;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 1575px) {
    width: 100px;
    max-width: 150px;
  }
}

.age-col {
  box-sizing: border-box;
  width: 80px;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 1575px) {
    width: 75px;
  }
}

.mrn-col {
  box-sizing: border-box;
  width: 100px;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 1575px) {
    width: 85px;
  }
}

.fall-risk-col {
  box-sizing: border-box;
  width: 80px;
  display: flex;
  flex-grow: 1;
}

.pain-col {
  box-sizing: border-box;
  width: 75px;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 1575px) {
    width: 60px;
  }
}

.functionality-col {
  box-sizing: border-box;
  width: 110px;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 1575px) {
    width: 90px;
  }
}

.days-from-surgery-col {
  box-sizing: border-box;
  width: 150px;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 1575px) {
    width: 100px;
  }
}

// "Canceled (Form Not Sent)" Tab
.surgery-date-col {
  width: 150px;
  display: flex;
  flex-grow: 1;
}

.bmi-col {
  box-sizing: border-box;
  width: 50px;
  display: flex;
  flex-grow: 1;
}

.comorbidities-col {
  box-sizing: border-box;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  span {
    padding-right: 0 !important;
  }
}

.post-op-col {
  box-sizing: border-box;
  width: 100px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: var(--purpleLightGray);
  @media only screen and (max-width: 1575px) {
    width: 85px;
  }
}

.covid-col {
  box-sizing: border-box;
  width: 60px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: var(--purpleLightGray);
  @media only screen and (max-width: 1575px) {
    width: 50px;
  }
}

.status-col {
  width: 100px;
  h5 {
    text-align: center;
    max-width: 100px;
  }
}

.send-form-col {
  box-sizing: border-box;
  width: 100px;
  display: flex;
  flex-grow: 1;
  justify-content: center;

  img {
    cursor: pointer;
  }
}
