#confirmation-modal {
  opacity: 1 !important;
}
#confirmation-modal-component {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 20;
  display: block !important;

  #background {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0.7;
  }

  .confirmation-modal-div {
    position: absolute;
    height: 326px;
    width: 476px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 11;
    margin-top: 15%;
  }

  .conf-modal {
    opacity: 1;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;

    .conf-exit {
      width: 30px;
      cursor: pointer;
    }
  }
}
