#small-screen-background {
	width: 100vw;
	height: 100vh;
}

#words {
	padding-top: 100px;
	position: absolute;
	width: 100vw;
	z-index: 101;
	color: white;

	div {
		background-color: var(--blueDark);
		padding: 35px 0 40px 0;
		width: 60%;
		margin: auto;
		border-radius: 3px;
		border: 1px solid var(--blueDocspera); 
		position: relative;

		.exit {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 23px;
			cursor: pointer;
		}

		p{
			text-align: center;
			color: white;
			font-size: 3vw;
			padding-top: 5px;
			padding-left: 10px;
			padding-right: 10px;
			opacity: 1;
		}
	
		#bold {
			font-weight: 600;
			font-size: 4vw;
			opacity: 1 !important;
			color: var(--blueDocsperaLight);
		}
	}

}