.calendar-picker-container {
  position: relative;
  box-sizing: border-box;

  label {
    > svg {
      position: absolute;
      top: 38%;
      right: 10px;
      width: 16px;
      height: 16px;
      transform: rotate(90deg);

      &:hover {
        cursor: pointer;
      }
    }
  }

  input {
    border: 2px solid var(--light-gray);
    border-radius: 8px;
    height: 25px;
    padding-left: 10px;
  }

  .calendar-picker-popover {
    z-index: 999;
    background-color: white;
    box-shadow: var(--box-shadow-card);
    border: 1px solid var(--light-gray);
    border-radius: 10px;
    min-height: 44px;
    position: absolute;
    top: 35px;
    right: -10px;
    width: 350px;
    z-index: 1;

    > header {
      margin-top: 10px;

      > div:first-child,
      > div:last-child {
        border-radius: 6px;
        height: 30px;
        transition: var(--hover-transition);
        width: 30px;

        &:hover {
          background-color: var(--light-gray);
          cursor: pointer;
        }
      }

      > div:nth-child(2) {
        border: 0.5px solid var(--icon-idle-gray);
        border-radius: 8px;
        margin: 0 0.5rem;
        padding: 0.5rem;
        text-align: center;
        width: 225px;

        h4 {
          color: black !important;
          font: normal 500 16px/18px 'Rubik', sans-serif;
          margin: 0 auto;
        }
      }
    }

    #weekdays {
      border-bottom: 1px solid var(--light-gray);
      font-weight: 500;
      padding: 10px 0;

      > div {
        width: 48px;
        text-align: center;
      }
    }

    #month {
      > .week {
        > .day {
          height: 48px;
          font-weight: 500;
          transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
          width: 48px;
          position: relative;

          .total-cases {
            position: absolute;
            top: 2px;
            right: 4px;
            color: black;
            font-size: 12px;
            font-weight: 300;
          }

          > div {
            height: 48px;
            transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
            width: 48px;

            &.selected {
              border: 2px solid black;
              border-radius: 3px;
              box-shadow: var(--box-shadow-card);
              height: 44px;
            }

            &.not-month {
              opacity: 0.5;
            }

            &:hover {
              height: 44px;
              border: 2px solid black;
              border-radius: 3px;
              box-shadow: var(--box-shadow-card);
              cursor: pointer;
              color: black;
            }
          }
        }
      }
    }
  }
}
