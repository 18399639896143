.status-tool {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  box-shadow: -0.5px 0.5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
  min-width: 200px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;

  span {
    font-size: 16px;
  }

  hr {
    color: rgb(58, 39, 39);
  }
}

.survey-icon {
  width: 25px;
  margin-right: 10px;
}
