#notification {
  background-color: black;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow-card);
  color: white;
  display: block;
  height: 62px;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  padding: 16px;
  transition: 0.25s;
  min-width: 450px;
  max-width: calc(100vw - 50px);
  z-index: 100000;

  p {
    font-weight: 500;
    color: white;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-close {
  border: 1.5px solid black;
  border-radius: 100%;
  padding: 0;

  > div {
    margin-left: 1px;
  }

  &:hover {
    background-color: var(--red);
    border: 1.5px solid var(--red);
    color: white;
  }
}

.small {
  height: 24px;
  width: 24px;
}
